import {
  FastField,
  FieldProps,
  Form,
  Formik,
  FormikHelpers,
  FormikProps,
} from 'formik';
import React, { useState } from 'react';
import FieldDescription from '../Units/Description/Description';
import { debounce } from 'debounce';
import {
  AppBar,
  Box,
  Button,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  Input,
  InputAdornment,
  InputLabel,
  Tab,
  Tabs,
  Tooltip,
  Typography,
} from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';
import FieldCodeTnVed from '../Units/FieldCodeTNVED/FieldCodeTNVED';
import FieldWeightNet from '../Units/FieldWeightNet/FieldWeightNet';
import FieldCountry from '../Units/FieldCountry/FieldCountry';
import FieldPrice from '../Units/FieldPrice/FieldPrice';
import {
  Product,
  useCreateProductMutation,
  useUpdateProductMutation,
} from '../../generated/graphql';
import { ShowLoadingText } from '../../utils/helperComponents';
import { myProductFormSchema } from '../../utils/validationSchemes';
import { UNIT_STATES, DEBOUNCE, TEXT, CURRENCIES } from '../../utils/constants';
import { TSetStateBoolean } from '../../interfaces';
import { useSnackbar } from 'notistack';
import { BoxCentered } from '../BoxCentered/BoxCentered';
import { useAppFormContext } from '../../context/FormContext';
import useStyles from './styles';
import { When } from 'react-if';
import { QUERY_MY_PRODUCT_LIST } from '../../GraphQL/queries/getMyProductList';
import { useTranslation } from 'react-i18next';
import FieldCurrency from '../Units/FieldCurrency/FieldCurrency';
import UploadProductPhotoComponent from '../UploadProductPhoto/UploadProductPhotoComponent';

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <Box
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={1}>{children}</Box>}
    </Box>
  );
}

function a11yProps(index: any) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const MyProductForm: React.FC<{
  setEditingProduct: (
    value: ((prevState: Product | null) => Product | null) | Product | null,
  ) => void;
  setIsOpenUnitForm: TSetStateBoolean;
  editingProduct: Product | null;
  filter?: any;
  refetch: any;
  isProduct?: boolean;
}> = ({
  setEditingProduct,
  setIsOpenUnitForm,
  editingProduct,
  filter,
  refetch,
  isProduct,
}) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [tabValue, setTabValue] = React.useState(0);
  const [uploadProductPhotoFile, setUploadProductPhotoFile] =
    useState<File | null>(null);
  const [imgSrc, setImgSrc] = useState('');

  const { allCountries: countries } = useAppFormContext();

  const { t } = useTranslation();

  const [createProductMutation] = useCreateProductMutation();
  const [updateProductMutation] = useUpdateProductMutation();

  if (!countries.length) return <ShowLoadingText name='стран' />;

  const initialValues: Product = !editingProduct
    ? ({
        id: '',
        detailsDescriptionRU: '',
        detailsDescriptionEN: '',
        trademark: '',
        code: null,
        declaredValue: 0,
        countryId: 1,
        netWeight: 0,
        state: UNIT_STATES.NEW,
        name: '',
        sku: null,
        fnsku: '',
        upc: '',
        currency: CURRENCIES.USD,
        actualDimensions: {
          weight: 0,
          length: 0,
          width: 0,
          height: 0,
        },
        photo: '',
      } as Product)
    : ({
        ...editingProduct,
        ...(editingProduct.isCustomBarcodeId && {
          customBarcodeId: editingProduct.barcodeId?.substring(
            0,
            editingProduct.barcodeId?.length - 4,
          ),
        }),
        photo: editingProduct.photo
          ? `${process.env.REACT_APP_API_DOMAIN}/api/getProductFile/${editingProduct?.photo}`
          : '',
      } as Product);

  const handleSubmit = (values: any, helpers: FormikHelpers<Product>) => {
    const productVariables = {
      name: values.name || '',
      countryId: values.countryId.id,
      sku: values.sku || null,
      declaredValue:
        values.declaredValue && +values.declaredValue
          ? +values.declaredValue
          : 0,
      detailsDescriptionRU: values.detailsDescriptionRU || '',
      detailsDescriptionEN: values.detailsDescriptionEN || '',
      netWeight: values.netWeight && +values.netWeight ? +values.netWeight : 0,
      code: values.code && +values.code ? values.code : null,
      trademark: values.trademark || '',
      state: values.state || UNIT_STATES.NEW,
      ...(!editingProduct && { customBarcodeId: values.customBarcodeId }),
      ...(!editingProduct && { upc: values.upc }),
      currency: values.currency,

      package: {
        weight: +values.declaredDimensions.weight,
        length: +values.declaredDimensions.length,
        width: +values.declaredDimensions.width,
        height: +values.declaredDimensions.height,
      },
      ...(editingProduct && { photo: values.photo }),
    };

    if (editingProduct) {
      updateProductMutation({
        variables: {
          product: {
            ...productVariables,
            id: +values.id,
          },
          ...(!!uploadProductPhotoFile && { photo: uploadProductPhotoFile }),
        },
      })
        .then((value) => {
          if (value.data?.updateProduct) {
            setEditingProduct(null);
            setIsOpenUnitForm(false);
            enqueueSnackbar(t('app.productUpdated'), { variant: 'success' });
          }
        })
        .catch((reason) => {
          console.error(reason);
          reason?.message &&
            enqueueSnackbar(reason?.message, { variant: 'error' });
        })
        .finally(() => {
          if (!isProduct) {
            refetch({
              limit: filter.itemsLimit,
              offset: (filter.page! - 1) * filter.itemsLimit,
              search: filter.search,
            });
            helpers.setSubmitting(false);
          } else {
            refetch();
          }
        });
      return;
    }

    // CREATING NEW PRODUCT
    createProductMutation({
      variables: {
        product: productVariables,
        ...(!!uploadProductPhotoFile && { photo: uploadProductPhotoFile }),
      },

      refetchQueries: [{ query: QUERY_MY_PRODUCT_LIST }],
    })
      .then((value) => {
        if (value.data?.createProduct) {
          setIsOpenUnitForm(false);
          enqueueSnackbar(t('app.productSaved'), { variant: 'success' });
        }
      })
      .catch((reason) => {
        console.error(reason);
        reason?.message &&
          enqueueSnackbar(reason.message, { variant: 'error' });
      })
      .finally(() => {
        refetch({
          limit: filter.itemsLimit,
          offset: (filter.page! - 1) * filter.itemsLimit,
          search: filter.search,
        });
        helpers.setSubmitting(false);
      });
  };

  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setTabValue(newValue);
  };

  return (
    <>
      <Typography variant='h3' align='center'>
        {t('app.addProduct')}
      </Typography>

      <br />
      <Formik
        initialValues={
          {
            ...initialValues,
            countryId: countries.find(
              (country) => country.id === initialValues?.country?.id,
            ),
          } as any
        }
        onSubmit={handleSubmit}
        validationSchema={myProductFormSchema}
      >
        {({ setFieldValue, errors, values }: FormikProps<Product>) => {
          const setFieldValueDebounce = debounce(
            (name: string, value: string) => {
              setFieldValue(name, value);
            },
            DEBOUNCE,
          );
          console.log('values', values);

          return (
            <Form>
              <br />

              <AppBar
                color='inherit'
                style={{ marginBottom: '30px' }}
                position='static'
              >
                <Tabs
                  variant='fullWidth'
                  className={classes.tabs}
                  value={tabValue}
                  onChange={handleTabChange}
                >
                  <Tab label={t('app.total')} {...a11yProps(0)} />
                  <Tab label={t('app.customsInformation')} {...a11yProps(1)} />
                  <Tab label={t('app.packaging')} {...a11yProps(2)} />
                </Tabs>
              </AppBar>

              <TabPanel value={tabValue} index={0}>
                <Box display='flex'>
                  <Box display='flex' flexDirection='column' width={'100%'}>
                    <FastField name='name'>
                      {({ field: { value, ...field }, meta }: FieldProps) => {
                        return (
                          <FormControl
                            className={classes.inputLabel}
                            error={meta.touched && !!meta.error}
                          >
                            <InputLabel>{t('app.productName')}*</InputLabel>
                            <Input
                              disableUnderline
                              defaultValue={value}
                              {...field}
                              onChange={(e) =>
                                setFieldValueDebounce('name', e.target.value)
                              }
                            />

                            {meta.touched && meta.error && (
                              <FormHelperText>{meta.error}</FormHelperText>
                            )}
                          </FormControl>
                        );
                      }}
                    </FastField>

                    <FastField name='sku'>
                      {({ field: { value, ...field }, meta }: FieldProps) => {
                        return (
                          <FormControl
                            className={classes.inputLabel}
                            error={meta.touched && !!meta.error}
                          >
                            <InputLabel>
                              SKU{' '}
                              <Tooltip
                                className={classes.tooltipIconButton}
                                placement='top'
                                title={t('app.skuInstruction')}
                              >
                                <IconButton>
                                  <InfoIcon />
                                </IconButton>
                              </Tooltip>
                            </InputLabel>

                            <Input
                              disableUnderline
                              defaultValue={value}
                              {...field}
                              onChange={(e) =>
                                setFieldValueDebounce('sku', e.target.value)
                              }
                            />

                            {meta.touched && meta.error && (
                              <FormHelperText>{meta.error}</FormHelperText>
                            )}
                          </FormControl>
                        );
                      }}
                    </FastField>
                    <FastField name='upc'>
                      {({ field: { value, ...field }, meta }: FieldProps) => {
                        return (
                          <FormControl
                            className={classes.inputLabel}
                            error={meta.touched && !!meta.error}
                            disabled={!!editingProduct}
                          >
                            <InputLabel>UPC</InputLabel>
                            <Input
                              disableUnderline
                              defaultValue={value}
                              {...field}
                              onChange={(e) =>
                                setFieldValueDebounce('upc', e.target.value)
                              }
                            />
                            {meta.touched && meta.error && (
                              <FormHelperText>{meta.error}</FormHelperText>
                            )}
                          </FormControl>
                        );
                      }}
                    </FastField>
                    <FastField name='customBarcodeId'>
                      {({ field: { value, ...field }, meta }: FieldProps) => {
                        return (
                          <FormControl
                            className={classes.inputLabel}
                            error={meta.touched && !!meta.error}
                            disabled={!!editingProduct}
                          >
                            <InputLabel>
                              {t('app.barcode')}{' '}
                              <Tooltip
                                className={classes.tooltipIconButton}
                                placement='top'
                                title={t('app.productIDInfo')}
                              >
                                <IconButton>
                                  <InfoIcon />
                                </IconButton>
                              </Tooltip>
                            </InputLabel>

                            <Input
                              disableUnderline
                              defaultValue={value}
                              {...field}
                              onChange={(e) => {
                                if (!editingProduct) {
                                  setFieldValueDebounce(
                                    'customBarcodeId',
                                    e.target.value,
                                  );
                                }
                              }}
                            />
                            {meta.touched && meta.error && (
                              <FormHelperText>{meta.error}</FormHelperText>
                            )}
                          </FormControl>
                        );
                      }}
                    </FastField>
                  </Box>
                  <UploadProductPhotoComponent
                    setFile={setUploadProductPhotoFile}
                    setImgSrc={setImgSrc}
                    imgSrc={imgSrc}
                  />
                </Box>
              </TabPanel>

              <TabPanel value={tabValue} index={1}>
                <FieldDescription
                  editingUnitSelector={''}
                  setFieldValue={setFieldValueDebounce}
                />

                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6} md={4}>
                    <Box className={classes.priceWrapper}>
                      <FieldPrice
                        handlerChange={(e) =>
                          setFieldValueDebounce(e.target.name, e.target.value)
                        }
                        currency={String(values.currency)}
                      />
                      <FieldCurrency
                        fieldName='currency'
                        setFieldValue={setFieldValueDebounce}
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <FieldCountry
                      countries={countries}
                      setFieldValue={setFieldValueDebounce}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={2}>
                    <FieldWeightNet
                      handlerChange={(e) =>
                        setFieldValueDebounce(e.target.name, e.target.value)
                      }
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={2}>
                    <FieldCodeTnVed
                      handlerChange={(e) =>
                        setFieldValueDebounce(e.target.name, e.target.value)
                      }
                    />
                  </Grid>
                </Grid>
              </TabPanel>

              <TabPanel value={tabValue} index={2}>
                <div className={classes.packageItem}>
                  <div className={classes.root}>
                    <Typography style={{ marginBottom: '30px' }}>
                      {t('app.enterDimensionsWeight')}
                    </Typography>
                    <Grid container spacing={5}>
                      <Grid item xs={12} sm={3}>
                        <FastField name={'declaredDimensions.weight'}>
                          {({
                            field: { value, ...field },
                            meta,
                          }: FieldProps) => {
                            return (
                              <FormControl
                                className={classes.inputLabelPackage}
                                error={
                                  !!(meta.touched && meta.error && value !== 0)
                                }
                              >
                                <InputLabel
                                  shrink={false}
                                  htmlFor={`input-declaredDimensions.weight`}
                                >
                                  <Tooltip title={t(TEXT.GROSS)}>
                                    <span>{t('app.weight')}</span>
                                  </Tooltip>
                                </InputLabel>
                                <Input
                                  disableUnderline
                                  id={`input-declaredDimensions.weight`}
                                  {...field}
                                  inputProps={{
                                    type: 'number',
                                    step: 0.01,
                                    min: 0,
                                  }}
                                  startAdornment={
                                    <InputAdornment position='end'>
                                      {t('app.kg')}
                                    </InputAdornment>
                                  }
                                  defaultValue={value}
                                  onChange={(e) => {
                                    setFieldValue(
                                      'declaredDimensions.weight',
                                      e.target.value,
                                    );
                                  }}
                                  value={value}
                                />
                                {meta.touched && meta.error && value !== 0 && (
                                  <FormHelperText>{meta.error}</FormHelperText>
                                )}
                              </FormControl>
                            );
                          }}
                        </FastField>
                      </Grid>
                      <Grid item xs={12} sm={3}>
                        <FastField name={'declaredDimensions.length'}>
                          {({
                            field: { value, ...field },
                            meta,
                          }: FieldProps) => (
                            <FormControl
                              className={classes.inputLabelPackage}
                              error={
                                meta.touched && !!meta.error && value !== 0
                              }
                            >
                              <InputLabel
                                shrink={false}
                                htmlFor={`input-declaredDimensions.length`}
                              >
                                {t('app.length')}
                              </InputLabel>
                              <Input
                                disableUnderline
                                id={`input-declaredDimensions.length`}
                                {...field}
                                inputProps={{
                                  type: 'number',
                                  min: 0,
                                  step: 0.1,
                                }}
                                startAdornment={
                                  <InputAdornment position='end'>
                                    {t('app.cm')}
                                  </InputAdornment>
                                }
                                defaultValue={value}
                                onChange={(e) => {
                                  setFieldValue(
                                    'declaredDimensions.length',
                                    e.target.value,
                                  );
                                }}
                                value={value}
                              />
                              {meta.touched && !!meta.error && value !== 0 && (
                                <FormHelperText>{meta.error}</FormHelperText>
                              )}
                            </FormControl>
                          )}
                        </FastField>
                      </Grid>
                      <Grid item xs={12} sm={3}>
                        <FastField name={'declaredDimensions.width'}>
                          {({
                            field: { value, ...field },
                            meta,
                          }: FieldProps) => (
                            <FormControl
                              className={classes.inputLabelPackage}
                              error={
                                meta.touched && !!meta.error && value !== 0
                              }
                            >
                              <InputLabel
                                shrink={false}
                                htmlFor={`input-declaredDimensions.width`}
                              >
                                {t('app.width')}
                              </InputLabel>
                              <Input
                                disableUnderline
                                id={`input-declaredDimensions.width`}
                                {...field}
                                inputProps={{
                                  type: 'number',
                                  min: 0,
                                  step: 0.1,
                                }}
                                startAdornment={
                                  <InputAdornment position='end'>
                                    {t('app.cm')}
                                  </InputAdornment>
                                }
                                defaultValue={value}
                                onChange={(e) => {
                                  setFieldValue(
                                    'declaredDimensions.width',
                                    e.target.value,
                                  );
                                }}
                                value={value}
                              />
                              {meta.touched && !!meta.error && value !== 0 && (
                                <FormHelperText>{meta.error}</FormHelperText>
                              )}
                            </FormControl>
                          )}
                        </FastField>
                      </Grid>
                      <Grid item xs={12} sm={3}>
                        <FastField name={'declaredDimensions.height'}>
                          {({
                            field: { value, ...field },
                            meta,
                          }: FieldProps) => (
                            <FormControl
                              className={classes.inputLabelPackage}
                              error={
                                meta.touched && !!meta.error && value !== 0
                              }
                            >
                              <InputLabel
                                shrink={false}
                                htmlFor={`declaredDimensions.height`}
                              >
                                {t('app.height')}
                              </InputLabel>
                              <Input
                                disableUnderline
                                id={`declaredDimensions.height`}
                                {...field}
                                inputProps={{
                                  type: 'number',
                                  min: 0,
                                  step: 0.1,
                                }}
                                startAdornment={
                                  <InputAdornment position='end'>
                                    {t('app.cm')}
                                  </InputAdornment>
                                }
                                defaultValue={value}
                                onChange={(e) => {
                                  setFieldValue(
                                    'declaredDimensions.height',
                                    e.target.value,
                                  );
                                }}
                                value={value}
                              />
                              {meta.touched && !!meta.error && value !== 0 && (
                                <FormHelperText>{meta.error}</FormHelperText>
                              )}
                            </FormControl>
                          )}
                        </FastField>
                      </Grid>
                    </Grid>
                  </div>
                </div>
              </TabPanel>
              <When condition={Boolean(Object.values(errors).length)}>
                <p className={classes.errorsMessage}>
                  {t('app.checkAllFields')}
                </p>
              </When>
              <BoxCentered className={classes.boxModalButtons}>
                <Button
                  className={classes.modalButton}
                  type='submit'
                  variant='contained'
                >
                  {t('app.ok')}
                </Button>
                <Button
                  onClick={() => {
                    setIsOpenUnitForm(false);
                  }}
                  variant='contained'
                >
                  {t('app.cancel')}
                </Button>
              </BoxCentered>
            </Form>
          );
        }}
      </Formik>
    </>
  );
};

export default MyProductForm;
