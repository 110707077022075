import React, { useState } from 'react';
import { Container, LinearProgress } from '@material-ui/core';
import PublicRoutes from '../../routes/PublicRoutes';
import useStyles from './styles';
import {
  GetCurrentUserQuery,
  GetCurrentUserQueryVariables,
  useGetDeliveryCountriesQuery,
  useGetPublicConfigurationsQuery,
  User,
} from '../../generated/graphql';
import { useRouteMatch } from 'react-router-dom';
import { TRoutes } from '../../utils/helpers';
import Footer from '../../components/Footer/Footer';
import { useSnackbar } from 'notistack';
import { Header } from '../../components/Header/Header';
import { QUERY_CURRENT_USER } from '../../GraphQL/queries/getCurrentUser';
import { useApolloClient } from '@apollo/client';

const PublicLayout: React.FC<{ user?: User }> = ({ user }) => {
  const client = useApolloClient();
  const { enqueueSnackbar } = useSnackbar();
  const isRouteMatchDeliveryCalculator = useRouteMatch(
    TRoutes.SELECT_TYPE_CALCULATOR,
  );
  const isRouteMatchEula = useRouteMatch(TRoutes.EULA);

  const classes = useStyles();
  const [isMobileOpen, setMobileOpen] = useState(false);
  console.log('55555', 55555);

  const {
    loading: isLoadingPublicConfigurations,
    error: errorPublicConfigurations,
  } = useGetPublicConfigurationsQuery({
    fetchPolicy: 'cache-first',
  });

  const dataCachedQueryCurrentUser = client.readQuery<
    GetCurrentUserQuery,
    GetCurrentUserQueryVariables
  >({
    query: QUERY_CURRENT_USER,
  });

  const {
    data: dataCountries,
    loading: isLoadingCountries,
    error: errorCountries,
  } = useGetDeliveryCountriesQuery();

  errorCountries &&
    enqueueSnackbar(errorCountries.message, { variant: 'error' });
  errorPublicConfigurations &&
    enqueueSnackbar(errorPublicConfigurations.message, { variant: 'error' });

  if (isLoadingCountries || !dataCountries || isLoadingPublicConfigurations) {
    return <LinearProgress style={{ height: 10 }} />;
  }

  return (
    <Container className={classes.root}>
      {/* Header */}
      <Header
        isSidebarDisabled={true}
        isSidebarHidden={false}
        currentUser={dataCachedQueryCurrentUser}
        user={user}
        isMobileOpen={isMobileOpen}
        setMobileOpen={setMobileOpen}
      />

      {/* Main */}
      <main
        className={classes.wrapper}
        style={
          !isRouteMatchDeliveryCalculator && !isRouteMatchEula
            ? { maxWidth: '466px' }
            : {}
        }
      >
        <PublicRoutes user={user} />
      </main>

      {/* Footer */}
      <Footer />
    </Container>
  );
};

export default PublicLayout;
