import {
  Box,
  CircularProgress,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  Input,
  InputLabel,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import React, { ChangeEvent, useEffect, useState } from 'react';
import {
  AvailableWarehouseProductsItem,
  Exact,
  UploadB2BOrderProductsFromExcelMutation,
} from '../../../generated/graphql';
import { FastField, FieldProps, useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';
import useStyles from './styles';
import { IB2bShipmentsValues } from './B2bShipmentSendForm';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import { B2B_SHIPMENTS_PRODUCTS_TABLE_CELLS_ARRAY } from '../../../utils/constants';
import { useParams } from 'react-router';
import iconUploadFileUrl from '../../../assets/img/icon_upload_file.png';
import { useSnackbar } from 'notistack';
import {
  ApolloCache,
  DefaultContext,
  MutationFunctionOptions,
} from '@apollo/client';
import { FetchResult } from 'apollo-link';
import PhotoComponent from '../../../components/UploadProductPhoto/PhotoComponent';

type UploadProductsMutationType = (
  options?: MutationFunctionOptions<
    UploadB2BOrderProductsFromExcelMutation,
    Exact<{
      file: any;
      warehouseId: number;
    }>,
    DefaultContext,
    ApolloCache<UploadB2BOrderProductsFromExcelMutation>
  >,
) => Promise<FetchResult<UploadB2BOrderProductsFromExcelMutation>>;

const B2bShipmentSendFormStep2 = ({
  products,
  uploadProductsMutation,
  uploadProductsMutationLoading,
}: {
  uploadProductsMutation: UploadProductsMutationType;
  products: AvailableWarehouseProductsItem[];
  uploadProductsMutationLoading: boolean;
}) => {
  const classes = useStyles();
  const { id } = useParams<{ id: string }>();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { values, setFieldValue } = useFormikContext<IB2bShipmentsValues>();
  const [totalVolume, setTotalVolume] = useState(0);
  const [totalWeight, setTotalWeight] = useState(0);
  const [totalCost, setTotalCost] = useState('');

  useEffect(() => {
    // @ts-ignore
    if (id && values.initWarehouseId === values.warehouseId) {
      const productMap = new Map(
        products.map((product) => [product.product?.id, product]),
      );
      const newProducts = values.products.map((product: any) => {
        const selectedProduct = productMap.get(String(product.productId));

        return {
          productId: product.productId,
          quantity: product.quantity,
          ...selectedProduct,
        };
      });
      setFieldValue('products', newProducts);
      // @ts-ignore
    } else if (id && values.initWarehouseId !== values.warehouseId) {
      setFieldValue('products', []);
    }
    // eslint-disable-next-line
  }, []);

  const removeProductHandle = (id: string) => {
    const newArrayProducts = values.products.filter(
      (product) => String(product?.product?.id) !== id,
    );
    setFieldValue('products', newArrayProducts);
  };

  const totalQuantity = values.products.reduce((acc, product) => {
    return acc + product.quantity;
  }, 0);

  useEffect(() => {
    const totalWeight = values.products.reduce((acc, product) => {
      const { quantity } = product;
      if (product.product?.actualDimensions) {
        return Number(product.product.actualDimensions.weight) * quantity + acc;
      }
      return (
        Number(product?.product?.declaredDimensions?.weight) * quantity + acc
      );
    }, 0);
    setTotalWeight(totalWeight);
    //eslint-disable-next-line
  }, [totalQuantity]);

  useEffect(() => {
    const totalVolume = values.products.reduce((acc, product) => {
      const { quantity } = product;
      if (product.product?.actualDimensions) {
        const volume =
          Number(product.product.actualDimensions.width) *
          Number(product.product.actualDimensions.length) *
          Number(product.product.actualDimensions.height) *
          quantity *
          0.000001;
        return acc + volume;
      }
      const volume =
        Number(product?.product?.declaredDimensions?.width) *
        Number(product?.product?.declaredDimensions?.length) *
        Number(product?.product?.declaredDimensions?.height) *
        quantity *
        0.000001;
      return acc + volume;
    }, 0);

    setTotalVolume(totalVolume);
    //eslint-disable-next-line
  }, [totalQuantity]);

  useEffect(() => {
    const currencyTotals: Record<string, number> = {};

    values.products.forEach((product) => {
      if (product && product.product) {
        const { quantity } = product;
        const { declaredValue, currency } = product.product;
        if (!currencyTotals[currency as string]) {
          currencyTotals[currency as string] = 0;
        }
        if (declaredValue)
          currencyTotals[currency as string] += declaredValue * quantity;
      }
    });

    const result = Object.entries(currencyTotals)
      .map(([currency, total]) => `${total.toFixed(2)} ${currency}`)
      .join(' + ');

    setTotalCost(result);
    //eslint-disable-next-line
  }, [totalQuantity]);

  const handleUploadProducts = (e: ChangeEvent<HTMLInputElement>) => {
    if (uploadProductsMutationLoading) return;
    const file = e.target.files && e.target.files[0];

    if (file) {
      const productMap = new Map(
        products.map((product) => [product.product?.id, product]),
      );
      uploadProductsMutation({
        variables: { file, warehouseId: Number(values.warehouseId) },
        onCompleted: (response) => {
          if (response.uploadB2BOrderProductsFromExcel?.isSuccessful) {
            const newProducts =
              response?.uploadB2BOrderProductsFromExcel?.uploadResult?.map(
                (product: any) => {
                  const selectedProduct = productMap.get(
                    String(product.productId),
                  );

                  return {
                    productId: product.productId,
                    quantity: product.quantity,
                    ...selectedProduct,
                  };
                },
              );

            setFieldValue('products', newProducts);
          } else {
            response.uploadB2BOrderProductsFromExcel?.uploadErrors?.forEach(
              (error) => {
                enqueueSnackbar(
                  `${error?.productIdentifier} ${error?.errorMessage}`,
                  { variant: 'error' },
                );
              },
            );
          }
        },
      });
    }
    e.target.value = '';
  };

  return (
    <div>
      <Grid container spacing={2}>
        <Grid item xs={12} style={{ marginBottom: '20px' }}>
          <Box position={'relative'}>
            <Autocomplete
              fullWidth
              loadingText={`${t('app.upload')}...`}
              closeText={t('app.close')}
              openText={t('app.open')}
              clearText={t('app.clear')}
              noOptionsText={t('app.noItemsFound')}
              options={products}
              renderInput={(params) => (
                <div ref={params.InputProps.ref}>
                  <TextField
                    label={t('app.selectProduct2')}
                    {...params}
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: 'my_products',
                    }}
                  />
                </div>
              )}
              renderOption={(option) => {
                return (
                  <>
                    <PhotoComponent
                      photoName={option?.product?.photo}
                      width={'38px'}
                      mr='12px'
                    />
                    {`ID :${option?.product?.barcodeId?.split('.')[0]} SKU: ${
                      option?.product?.sku ? `[${option?.product?.sku}]` : ''
                    } ${t('app.availableReserved', {
                      value: option?.availableQuantity,
                      reserved: option?.reservedQuantity,
                    })} ${option?.product?.name}`}
                  </>
                );
              }}
              getOptionLabel={(option) => {
                return `ID: ${option?.product?.barcodeId?.split('.')[0]} SKU: ${
                  option?.product?.sku ? '[' + option?.product?.sku + ']' : ''
                } ${t('app.availableReserved', {
                  value: option?.availableQuantity,
                  reserved: option?.reservedQuantity,
                })} - ${option?.product?.name || ''}`;
              }}
              onChange={(e, newValue) => {
                if (
                  values.products.some(
                    (product) => product?.product?.id === newValue?.product?.id,
                  )
                ) {
                  return;
                }

                if (!newValue) {
                  return;
                }

                setFieldValue('products', [
                  ...values.products,
                  {
                    ...newValue,
                    productId: Number(newValue?.product?.id),
                    quantity: 1,
                  },
                ]);
              }}
            />
            <InputLabel
              htmlFor='upload-product-file'
              className={classes.uploadFileWarapper}
            >
              <Box display={'flex'} alignItems={'center'}>
                {uploadProductsMutationLoading ? (
                  <Box
                    sx={{ display: 'flex', justifyContent: 'center' }}
                    style={{
                      height: '24px',
                      marginRight: '12px',
                    }}
                  >
                    <CircularProgress />
                  </Box>
                ) : (
                  <img
                    src={iconUploadFileUrl}
                    alt={'upload_photo'}
                    style={{
                      height: '24px',
                      marginRight: '12px',
                    }}
                  />
                )}

                <Typography>Загрузить списком в Excel </Typography>
              </Box>
            </InputLabel>

            <input
              id='upload-product-file'
              style={{ display: 'none' }}
              type='file'
              accept='.xlsx,.xls,.xlsm,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel'
              onChange={handleUploadProducts}
            />
          </Box>
        </Grid>
      </Grid>
      {values.products.length ? (
        <TableContainer component={Paper} className={classes.noStylesTable}>
          <Table aria-label='simple table'>
            <TableHead>
              <TableRow>
                {B2B_SHIPMENTS_PRODUCTS_TABLE_CELLS_ARRAY.map((text, i) => (
                  <TableCell
                    key={text}
                    align={!i ? 'left' : i === 1 ? 'right' : 'right'}
                  >
                    {t(text)}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {values.products.map((option, index) => (
                <TableRow>
                  <TableCell>
                    <Box display={'flex'} alignItems={'center'}>
                      <PhotoComponent
                        photoName={option?.product?.photo}
                        width={'38px'}
                        mr='12px'
                      />
                      {`ID: ${option?.product?.barcodeId?.split('.')[0]} SKU: ${
                        option?.product?.sku ? `[${option?.product?.sku}]` : ''
                      } ${t('app.availableProducts', {
                        value: option?.availableQuantity,
                      })} ${option?.product?.name}`}
                    </Box>
                  </TableCell>
                  <TableCell align='right'>
                    <FastField name={`products[${index}].quantity`}>
                      {({ field: { value, ...field }, meta }: FieldProps) => {
                        return (
                          <FormControl
                            style={{ width: '90px' }}
                            error={meta.touched && !!meta.error && value !== 0}
                          >
                            <Input
                              placeholder={t('app.pcs')}
                              disableUnderline
                              id={`input-quantity`}
                              {...field}
                              defaultValue={value}
                              onChange={(e) => {
                                setFieldValue(
                                  `products[${index}].quantity`,
                                  +e.target.value,
                                );
                              }}
                              value={value}
                              inputProps={{
                                type: 'number',
                                step: 1,
                                min: 1,
                              }}
                            />
                            {meta.touched && !!meta.error && (
                              <FormHelperText>{t(meta.error)}</FormHelperText>
                            )}
                          </FormControl>
                        );
                      }}
                    </FastField>
                  </TableCell>
                  <TableCell align='right'>
                    <IconButton
                      onClick={() => {
                        removeProductHandle(String(option?.product?.id));
                      }}
                    >
                      <DeleteForeverIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : null}
      {!!values.products.length && (
        <Box className={classes.productsInformation}>
          <Typography>{`${t(
            'app.totalQuantity',
          )}: ${totalQuantity}`}</Typography>
          <Typography>{`${t('app.totalWeight')}: ${totalWeight.toFixed(2)} ${t(
            'app.kg',
          )}`}</Typography>
          <Typography>{`${t('app.totalVolume')}: ${parseFloat(
            totalVolume.toFixed(6),
          )} ${t('app.m3')}`}</Typography>
          <Typography>{`${t('app.totalCost')}: ${totalCost}`}</Typography>
        </Box>
      )}
    </div>
  );
};

export default B2bShipmentSendFormStep2;
